import { useEffect, useState } from "react";

const RATE = 10; // milliseconds/count

export interface IncreasingNumberProps {
  value: number;
  unit?: string;
}

export default function IncreasingNumber(props: IncreasingNumberProps) {
  const [current, setCurrent] = useState<number>();

  useEffect(() => {
    const intervalHandle = setInterval(() => {
      setCurrent((current) => {
        current ??= 0;
        if (current < props.value) current++;
        else clearInterval(intervalHandle);
        return current;
      });
    }, RATE);
    return () => clearInterval(intervalHandle);
  }, [props.value]);

  return (
    <span>
      <noscript>
        {props.value}
        {props.unit}
      </noscript>
      <span
        className={
          "transition-opacity" + (current === undefined ? " opacity-0" : "")
        }
        style={{ transitionDuration: `${props.value * RATE}ms` }}
      >
        {/* Zero width space to avoid layout shift */}
        &#8203;
        {current}
        {current === undefined ? null : props.unit}
      </span>
    </span>
  );
}
