import type { HTMLProps } from "react";
import { useEffect } from "react";
import IncreasingNumber from "../core/IncreasingNumber";

export interface Stat {
  solved: number;
  total: number;
  thing: string;
}

interface Props extends Omit<HTMLProps<HTMLDivElement>, "id"> {
  stat: Stat;
}

export default function StatBlock(props: Props) {
  const { stat, ...rest } = props;
  const { solved, total, thing } = stat;

  const id = `StatBlock-${thing.replaceAll(" ", "-").toLowerCase()}`;
  const value = Math.round((solved / total) * 100);

  useEffect(() => {
    fadeIn(id, "StatBlock-counts", 1800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={id} {...rest}>
      <div className="StatBlock-counts mb-2 font-medium text-lg whitespace-nowrap opacity-0 transition-opacity duration-500">
        {solved}&thinsp;/&thinsp;{total}
      </div>
      <p className="font-bold text-6xl text-xbow mb-4">
        <IncreasingNumber value={value} unit="%" />
      </p>
      <p className="StatBlock-thing font-medium text-3xl opacity-70 max-w-[215px] mx-auto leading-tight">
        {stat.thing}
      </p>
      <div className="StatBlock-solved font-medium text-xl !leading-tight opacity-40 mt-2">
        solved
      </div>
    </div>
  );
}

function fadeIn(id: string, cls: string, delay: number) {
  setTimeout(() => {
    document
      .querySelectorAll(`#${id} .${cls}`)
      ?.forEach((x) => x.classList.add("!opacity-40"));
  }, delay);
}
